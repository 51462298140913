<template>
  <div class="mcp-choose-industries">
    <div class="mcp-ci-wrapper" :class="{ 'mcp-ci-shadow': mcpActiveStep === 2 }">
      <DgInput
        placeholder="Filter by Industries e.g. Venture Capital on this page"
        v-model="searchQuery"
        @change="searchList"
      />
      <DgAccordion class="dg-mcp-accordion dg-scrollbar" ref="dgAccordion">
        <DgAccordionItem v-for="(industry, idx) in modifedList" :key="idx" :id="idx" :title="industry.name">
          <DgAccordionActions slot="actions">
            <DgButton
              size="x-small"
              :textIcon="isIndustrychosen(industry.id) ? 'dgi-check' : 'dgi-d-add'"
              :type="isIndustrychosen(industry.id) ? 'success' : 'primary'"
              :hover="false"
              @click="toggleIndustry(industry)"
            >
              <span v-if="!isIndustrychosen(industry.id)">Add</span>
              <span v-else>Added</span>
            </DgButton>
          </DgAccordionActions>
          <DgAccordionContent>
            <ul>
              <li v-for="(audit_process, idx) in industry.audit_processes" :key="idx">
                <span v-if="audit_process && audit_process.id">{{ audit_process.name }}</span>
                <ul>
                  <li v-for="(question_group, idx) in audit_process.question_groups" :key="idx">
                    <span v-if="question_group && question_group.id">{{ question_group.name }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </DgAccordionContent>
        </DgAccordionItem>
        <div v-if="showLoader" class="loader">
          <div class="loading"></div>
        </div>
        <DgPagination
          v-if="pagination && pagination !== 1"
          v-show="!(searchQuery && searchQuery !== null)"
          :totalPages="pagination"
        />
      </DgAccordion>
    </div>
    <DgActionBar>
      <DgButtonGroup>
        <DgButton @click="submitIndustries()" :disabled="mcpchosenIndustries && mcpchosenIndustries.length === 0"
          >Continue</DgButton
        >
      </DgButtonGroup>
    </DgActionBar>
  </div>
</template>

<script>
import { Structural, Basic, Form } from "@/dgui-customer-components/";
import { mapGetters } from "vuex";

export default {
  name: "MCP-ChooseIndustries",
  components: {
    DgAccordion: Structural.DgAccordion.DgAccordion,
    DgAccordionItem: Structural.DgAccordion.DgAccordionItem,
    DgAccordionActions: Structural.DgAccordion.DgAccordionActions,
    DgAccordionContent: Structural.DgAccordion.DgAccordionContent,
    DgActionBar: Structural.DgActionBar,
    DgPagination: Basic.DgPagination,
    DgButton: Basic.DgButton,
    DgButtonGroup: Basic.DgButtonGroup,
    DgInput: Form.DgInput,
  },
  data() {
    return {
      searchQuery: null,
      pagination: null,
      showLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      mcpIndustries: "getMcpIndustries",
      mcpActiveStep: "getMcpActiveStep",
      mcpchosenIndustries: "getMcpchosenIndustries",
      mcpAuditProcessById: "getMcpAuditProcessById",
      mcpQuestionGroupById: "getMcpQuestionGroupById",
    }),
    modifedList() {
      if (this.searchQuery) {
        return this.$store.getters.getMcpIndustriesByQuery(this.searchQuery);
      }
      return this.mcpIndustries;
    },
  },
  created() {
    this.fetchIndustries();

    if (!this.$route.query.page) {
      this.$router.push({
        path: this.$route.path,
        query: {
          page: "1",
        },
      });
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.page !== from.query.page) {
        this.showLoader = true;
        this.$refs.dgAccordion.$el.scrollTop = 0;
        this.fetchIndustries();
      }
    },
  },
  methods: {
    submitIndustries() {
      if (this.mcpchosenIndustries.length === 0) {
        return this.$dgToast.show({
          type: "warning",
          title: "Choose an industry",
          content: "You need to choose at least one industry before proceeding to the next step",
          closeTimeout: 4000,
        });
      }
      this.$router.push({ name: "MCPStepTwo" });
      this.$store.dispatch("setMcpActiveStep", 2);
    },
    searchList(value) {
      this.searchQuery = value;
    },
    toggleIndustry(industry) {
      if (this.isIndustrychosen(industry.id)) {
        return this.$store.dispatch("removeMcpchosenIndustry", industry);
      }
      this.$store.dispatch("addMcpchosenIndustry", industry);
    },
    isIndustrychosen(industryId) {
      const checkIndustry = this.mcpchosenIndustries.find(industry => industry.id === industryId);

      if (checkIndustry === undefined) {
        return false;
      }

      return true;
    },
    fetchIndustries() {
      this.$http
        .get(`/api1/v2/industries?by_industry_type=industry_2&page=${this.$route.query.page}`)
        .then(response => {
          this.$store.commit("ADD_MCP_INDUSTRIES", response.data.data);
          this.pagination = response.data.total_pages;
          if (this.showLoader) {
            this.showLoader = false;
          }
        })
        .catch(error => {
          this.$dgToast.show({
            type: "info",
            title: "Error",
            content: error,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mcp-choose-industries {
  width: 100%;

  .mcp-ci-wrapper {
    padding: 10px 0;

    .dg-mcp-accordion {
      height: calc(100vh - 312px);
      overflow-y: overlay;
      margin: 0 -20px;
      padding: 0 20px;

      .loader {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.85);
        position: absolute;
        display: flex;
        justify-content: center;
        top: 0;
        left: 0;

        .loading {
          display: inline-block;
          align-self: center;
          width: 50px;
          height: 50px;
          border: 8px solid #0b344b;
          border-radius: 50%;
          border-top-color: #f1f1f1;
          animation: spin 1s ease-in-out infinite;
          -webkit-animation: spin 1s ease-in-out infinite;
        }

        @keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
      }
    }

    &.mcp-ci-shadow {
      margin-right: 15px;
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
      padding: 10px 20px 0 0;
      height: inherit;
    }
  }

  .dg-scrollbar {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c3c3c3;
      cursor: pointer;

      &:hover {
        background-color: darken(#c3c3c3, 15%);
      }
    }
  }

  .dg-bottom-bar {
    left: 0;
  }
}
</style>
