<template>
  <div class="container">
    <DgPanel class="mcp-wrapper" v-if="mcpCustomer">
      <DgMcpHeader :step="stepDetails" :customer="mcpCustomer.name" :industries="selectedIndustries" />
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </DgPanel>
    <DgMcpModal
      v-if="mappedIndustry.length"
      :list="mappedIndustry"
      :header="this.setModalHeader"
      primaryButton="Continue"
      secondaryButton="Back to customers"
      @onAccept="resetMappedIndustry()"
      @onReject="redirectBack()"
      @closeModal="resetMappedIndustry()"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Structural, Basic, MCP } from "@/dgui-customer-components/";

export default {
  name: "MCP-Layout",
  components: {
    DgPanel: Structural.DgPanel,
    DgMcpHeader: MCP.DgMcpHeader,
    DgMcpModal: MCP.DgMcpModal,
  },
  data() {
    return {
      mappedIndustry: [],
    };
  },
  computed: {
    ...mapGetters({
      mcpCustomer: "getMcpCustomer",
      mcpActiveStep: "getMcpActiveStep",
      mcpStep: "getMcpStep",
      mcpchosenIndustries: "getMcpchosenIndustries",
      companyData: "companyData",
    }),
    stepDetails() {
      return this.mcpStep(this.mcpActiveStep);
    },
    selectedIndustries() {
      let industries = [];
      this.mcpchosenIndustries.forEach(industry => {
        industries.push(industry.name);
      });
      return industries;
    },
    setModalHeader() {
      if (this.mcpCustomer && this.mcpCustomer.name) {
        return `${this.mcpCustomer.name} already has a mapped industry,<br />submiting new will override old industry:`;
      }
      return "";
    },
  },
  created() {
    this.setMcpCustomer();
    this.checkIfIndustryMapped();

    window.addEventListener(
      "beforeunload",
      () => {
        return "Changes that you made wont be saved.";
      },
      false
    );
  },
  methods: {
    setMcpCustomer() {
      this.$store.commit("SET_MCP_CUSTOMER", this.companyData);
    },
    checkIfIndustryMapped() {
      this.$http
        .get(`/api1/v2/customers/${this.$route.params.id}/customer_industry`)
        .then(response => {
          if (JSON.stringify(response.data.data) !== "{}") {
            this.mappedIndustry = response.data.data.audit_processes;
          }
        })
        .catch(error => {
          this.$dgToast.show({
            type: "info",
            title: "Error",
            content: "An error has occurred, redirecting back",
          });
          this.redirectBack();
        });
    },
    resetMappedIndustry() {
      this.mappedIndustry = [];
    },
    redirectBack() {
      window.location.replace("/redirect");
    },
  },
};
</script>

<style lang="scss" scoped>
.mcp-wrapper {
  width: 100%;
  height: calc(100vh - 140px);
  margin: 0 auto;
  margin-top: 20px;
}
</style>
